<template>
  <b-row class="">
    <b-col cols="12" md="9" xl="8">
      <b-card class="py-1">
        <b-form>
          <!-- Libellé -->
          <b-form-group label="Libellé">
            <template #label> Libellé <span class="text-danger">*</span> </template>

            <b-form-input
              id="libelle"
              v-model="newArticle.libelle"
              name="libelle"
              placeholder="Libellé de l'article"
            />
            <span
              class="text-danger"
              style="font-size: 12px"
              v-if="errorInput.path === 'libelle'"
            >
              {{ errorInput.message }}
            </span>
          </b-form-group>

          <!-- Categorie du produit -->
          <b-form-group label="Catégorie de l'article">
            <template #label>
              Catégorie de l'article <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="addNewCategorie === false"
              v-model="newArticle.categorie"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categories"
              label="libelle"
              placeholder="Choisir la catégorie du produit"
            >
              <template #list-header>
                <li
                  v-b-toggle.sidebar-invoice-add-new-customer
                  class="add-new-client-header d-flex align-items-center mx-1 py-1 text-primary"
                  @click="addNewCategorie = true"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                  <span class="align-middle ml-25 addcat"
                    >Ajouter une nouvelle catégorie</span
                  >
                </li>
              </template>
            </v-select>

            <div class="row d-flex mb-1" v-if="addNewCategorie === true">
              <div class="col-11 mr-0 pr-0">
                <validation-provider #default="{ errors }" name="newcategorieValue">
                  <b-form-input
                    id="register-nom"
                    v-model="categorieNewValue"
                    name="categorieNewValue"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ajouter une nouvelle categorie"
                  />
                </validation-provider>
              </div>

              <div class="col-1 m-auto pl-1">
                <feather-icon
                  @click="addNewCategorie = false"
                  icon="XCircleIcon"
                  class="caticon"
                  style="font-size: 38px"
                />
              </div>
            </div>
            <span
              class="text-danger"
              style="font-size: 12px"
              v-if="errorInput.path === 'categories'"
            >
              {{ errorInput.message }}
            </span>
          </b-form-group>

          <!-- Type de produit -->
          <b-form-group label="Type de produit">
            <template #label>
              Type de produit <span class="text-danger">*</span>
            </template>
            <v-select
              v-model="newArticle.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="optionType"
              placeholder="Renseigner le type du produit"
            />
            <span class="text-danger" v-if="errorInput.path === 'type'">
              {{ errorInput.message }}
            </span>
          </b-form-group>

          <!-- Data de creation -->
          <b-row class="justify-content-center">
            <b-col>
              <b-form-group label-for="prix_achat" label="Prix de vente">
                <template #label> Date de fabrication (Produit) </template>
                <b-form-input
                  :disabled="newArticle.type === 'produit' ? false : true"
                  id="prix_achat"
                  v-model="newArticle.date_creation"
                  name="prix_achat"
                  placeholder="Prix d'achat"
                  type="number"
                  min="0"
                />
              </b-form-group>
              <span
                class="text-danger"
                style="font-size: 12px"
                v-if="errorInput.path === 'date_creation'"
              >
                {{ errorInput.message }}
              </span>
            </b-col>
            <b-col>
              <b-form-group label-for="prix_achat" label="Prix de vente">
                <template #label> Date de peremption (Produit) </template>
                <b-form-input
                  :disabled="newArticle.type === 'produit' ? false : true"
                  id="prix_achat"
                  v-model="newArticle.date_expiration"
                  name="prix_achat"
                  placeholder="Prix d'achat"
                  type="number"
                  min="0"
                />
              </b-form-group>
              <span
                class="text-danger"
                style="font-size: 12px"
                v-if="errorInput.path === 'date_expiration'"
              >
                {{ errorInput.message }}
              </span>
            </b-col>
          </b-row>

          <!-- Seuil d'approvisionnement -->
          <b-form-group label="Seuil">
            <template #label>
              Seuil d'approvisionnement <span class="text-danger">*</span>
            </template>

            <b-form-input
              id="libelle"
              v-model="newArticle.seuil"
              name="libelle"
              placeholder="Seuil d'approvisionnement"
            />
            <span
              class="text-danger"
              style="font-size: 12px"
              v-if="errorInput.path === 'seuil'"
            >
              {{ errorInput.message }}
            </span>
          </b-form-group>

          <!-- Poids et unité -->
          <b-row class="justify-content-center">
            <b-col>
              <!-- Poids -->
              <b-form-group label="Unité de achat" label-for="register-achat">
                <v-select
                  v-model="newArticle.unite_achat"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionUnite"
                  placeholder="L'unité d'achat de l'article"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <!-- Unité -->
              <b-form-group label="Unité de vente" label-for="register-vente">
                <v-select
                  v-model="newArticle.unite_vente"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionUnite"
                  placeholder="L'unité de vente de l'article"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Prix achat et Prix de vente -->
          <b-row class="justify-content-center">
            <b-col>
              <b-form-group label-for="prix_achat" label="Prix de vente">
                <template #label> Prix d'achat </template>
                <b-form-input
                  id="prix_achat"
                  v-model="newArticle.prix_achat"
                  name="prix_achat"
                  placeholder="Prix d'achat"
                  type="number"
                  min="0"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="prix_vente" label="Prix de vente">
                <template #label>
                  Prix de vente <span class="text-danger">*</span>
                </template>

                <b-form-input
                  id="prix_vente"
                  v-model="newArticle.prix_vente"
                  name="prix_vente"
                  placeholder="Prix de vente"
                  type="number"
                  min="0"
                />
                <span class="text-danger" v-if="errorInput.path === 'prix_vente'">
                  {{ errorInput.message }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Type de produit -->
          <b-form-group label="Quantité" label-for="qte">
            <template #label> Quantité <span class="text-danger">*</span> </template>
            <b-form-input
              id="qte"
              v-model="newArticle.qte"
              name="qte"
              placeholder="Quantité de l'article"
              type="number"
              min="0"
            />
            <span class="text-danger" v-if="errorInput.path === 'qte'">
              {{ errorInput.message }}
            </span>
          </b-form-group>

          <!-- lien video -->
          <!-- <b-form-group label-for="movie" label="Lien de vidéo">
        <b-form-input
          id="movie"
          v-model="newArticle.movie"
          name="movie"
          placeholder="Lien d'une vidéo"
        />
      </b-form-group> -->

          <!-- Image du produit -->
          <div>
            <label for="">Image du produit</label>
            <b-form-file
              class="text-center mb-1"
              v-model="newArticle.file"
              @change="processFile2($event)"
              placeholder="Images du produit"
              drop-placeholder="Glisser un fichier ici..."
              multiple
            />
          </div>

          <!-- Description du produit -->
          <b-form-group>
            <label for="taxeValue">Description </label>
            <b-form-textarea
              id="textarea"
              v-model="newArticle.description"
              placeholder="Entrer les details de l'article ici"
              rows="5"
              max-rows="6"
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>
      </b-card>
    </b-col>

    <b-col cols="12" md="3" xl="4">
      <b-card class="">
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          class="mb-50 w-100"
          @click.stop.prevent="AddNewArticle"
        >
          <span v-if="state.loading === false">Ajouter</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>

        <b-button
          :disabled="state.loading === true ? true : false"
          class="w-100"
          variant="outline-primary"
          :to="{ name: 'articles' }"
        >
          <span>Liste des articles </span>
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { reactive, ref } from "@vue/composition-api";
import vSelect from "vue-select";
import axios from "axios";
import URL from "@/views/pages/request";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { toast_sucess } from "@/utils/qToast";
import { qArticleValidator } from "@/utils/validators/ArticleValidate";

export default {
  components: {
    vSelect,
  },
  props: {
    categories: Array,
    articles: Array,
    optionType: {
      default: function () {
        return ["service", "produit"];
      },
      type: Array,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { root }) {
    const optionUnite = ref([
      "Kg",
      "Unité",
      "Litre",
      "Sacs",
      "Pièce",
      "Sachet",
      "Bouteille",
    ]);
    const state = reactive({
      loading: false,
    });

    const addNewCategorie = ref(false);
    const categorieNewValue = ref("");
    const newArticle = reactive({
      libelle: "",
      categorie: "",
      type: "",
      poids: "",
      unite: "",
      prix_achat: "",
      prix_vente: "",
      unite_vente: "",
      unite_achat: "",
      qte: "",
      movie: "",
      file: null,
      description: "",
      seuil: "",
      date_expiration: "",
      date_creation: "",
    });

    const processFile2 = (event) => {
      newArticle.file = event.target.files[0];
      if (event.target.length !== 0) {
        console.log(newArticle.file);
      }
    };

    const errorInput = reactive({
      path: "",
      message: "",
    });

    // *****
    // ****
    // FUNCTION POUR AJOUT D'ARTICLE
    // ****
    // *****
    const AddNewArticle = async () => {
      const { isValid, EI } = await qArticleValidator(newArticle);

      if (!isValid) {
        state.loading = false;
        errorInput.path = EI.path;
        errorInput.message = EI.message;
      } else {
        state.loading = true;
        const config = {
          headers: {
            Accept: "application/json",
          },
        };
        const data = new FormData();
        if (addNewCategorie.value === false) {
          data.append("action", "ancien");

          data.append("category", newArticle.categorie.id);
        } else {
          data.append("action", "nouveau");

          data.append("categorie", categorieNewValue.value);
          props.categories.unshift(categorieNewValue.value);
        }
        console.log(newArticle.file);
        data.append("image", newArticle.file === null ? null : newArticle.file[0]);
        data.append("libelle", newArticle.libelle);
        data.append("type", newArticle.type);
        data.append("prix_vente", newArticle.prix_vente);
        data.append("qte", newArticle.qte);
        data.append("description", newArticle.description);
        data.append("prix_achat", newArticle.prix_achat);
        data.append("unite_vente", newArticle.unite_vente);
        data.append("unite_achat", newArticle.unite_achat);
        data.append("qte", newArticle.qte);
        data.append("poids", newArticle.poids);
        data.append("lien_video", newArticle.movie);
        await axios
          .post(URL.ARTICLE_CREATE, data)
          .then((response) => {
            if (response.data) {
              const article = newArticle;
              state.loading = false;

              root.$bvModal.hide("e-add-new-article");
              toast_sucess(root, "success", "top-right", "Article créer avec succès !");
              // console.log(addNewCategorie.value, response.data.article.media[0].original_url);
              props.articles.unshift({
                id: response.data.article.id,
                libelle: article.libelle,
                type: article.type,
                prix_achat: article.prix_achat === "" ? 0 : article.prix_achat,
                prix_vente: article.prix_vente,
                qte: article.qte,
                commande: 0,
                media:
                  response.data.article_img !== ""
                    ? response.data.article_img
                    : "https://via.placeholder.com/150/4B0082/FFFFFF/?text=Ediqia",
                categorie:
                  addNewCategorie.value === false
                    ? article.categorie
                    : categorieNewValue.value,
                created_at: moment().format("DD-MM-YYYY"),
                created_at_time: moment().toDate().getTime().toString(),
              });

              root.$nextTick(() => {
                const input = document.querySelector("#invoice-data-article");
                if (input) {
                  input.focus();
                }
              });

              addNewCategorie.value = false;
              newArticle.libelle = "";
              newArticle.categorie = "";
              categorieNewValue.value = "";
              newArticle.type = "";
              newArticle.prix_vente = "";
              newArticle.description = "";
              newArticle.prix_achat = "";
              newArticle.qte = "";
              newArticle.unite_vente = "";
              newArticle.unite_achat = "";
              newArticle.poids = "";
              newArticle.movie = "";
              newArticle.unite = "";
              newArticle.file = "";
            }
          })
          .catch((error) => {
            state.loading = false;
            console.log(error.message);
          });
      }
    };

    return {
      optionUnite,
      errorInput,
      addNewCategorie,
      categorieNewValue,

      AddNewArticle,
      newArticle,
      processFile2,
      state,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
